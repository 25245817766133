<template>
  <v-toolbar dense class="toolbar" dark height="70px">
    <v-col class="h-100 d-flex align-items-center">
      <!-- plateau state logo -->
      <img
        alt="Plateau State Logo"
        class="shrink header-image"
        src="@/assets/images/png/Plateau state logo.png"
      />
      <!-- OpenHISA logo -->
      <img
        src="@/assets/images/png/OpenHISA Identity.png"
        alt="OpenHISA Logo"
        class="ml-3 shrink header-image"
      />
      <!-- home route (navbar-brand) -->
      <router-link to="/" exact class="mr-auto">
        <v-toolbar-title class="ml-3 mt-2 font-weight-bold h4"
          >OpenHISA</v-toolbar-title
        >
      </router-link>

      <!-- Media Query for big screen (Desktop/Laptop) -->
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-toolbar-items>
          <!--  renders user avatar and dropdown menu when user is logged in -->
          <template v-if="user">
            <v-avatar size="50" class="mr-4 border border-sm">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <img
                    :src="`https://ui-avatars.com/api/?name=${user.first_name}${user.last_name}`"
                    :alt="`${user.user_firstname} ${user.user_lastname} avatar`"
                    v-on="on"
                  />
                </template>
                <v-list style="width: 150px">
                  <!-- Staff dropdown -->
                  <v-list-item v-if="userGroup" @click="dialog = true">
                    <v-list-item-title>
                      <small>Logout</small>
                    </v-list-item-title>
                  </v-list-item>
                  <!-- Normal user dropdown -->
                  <v-list-item
                    v-else
                    v-for="(item, index) in menu"
                    :key="index"
                    @click="item.event"
                  >
                    <v-list-item-title>
                      <small>{{ item.text }}</small>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-avatar>

            <div
              class="d-inline-block d-flex flex-column justify-content-center mr-6"
            >
              <small>{{ user.user_firstname }} {{ user.user_lastname }}</small>
              <small class="font-weight-bold">
                {{ userGroup && userGroup.split("_").join(" ").toUpperCase() }}
              </small>
            </div>
          </template>
          <!-- navigation routes -->
          <v-btn
            class="text-capitalize font-weight-bold"
            v-for="(route, index) in renderedRoutes"
            :key="index"
            text
            :to="route.url"
            small
          >
            {{ route.name }}
          </v-btn>
        </v-toolbar-items>
      </template>

      <!-- Media Query for small screen (Mobile/Tablets) -->
      <template v-else>
        <!-- Mobile AppBar -->
        <v-menu top offset-y :close-on-content-click="true">
          <template v-slot:activator="{ on, attrs }">
            <v-app-bar-nav-icon v-bind="attrs" v-on="on"></v-app-bar-nav-icon>
          </template>
          <v-list>
            <v-list-item v-for="(route, index) in renderedRoutes" :key="index">
              <v-list-item-title>
                <v-btn text color="black" :to="route.url">
                  {{ route.name }}
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-col>

    <!-- logout modal -->
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="headline">
          <small>Attention</small>
        </v-card-title>

        <v-card-text>
          <small
            >You are about to be signed out. Do you want to continue?</small
          >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            class="text-capitalize"
            text
            @click="handleLogout"
          >
            <small>Proceed</small>
          </v-btn>
          <v-btn
            color="danger darken-1"
            class="text-capitalize"
            text
            @click="dialog = false"
          >
            <small>Cancel</small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end logout modal -->
  </v-toolbar>
</template>

<script>
import { mapGetters } from "vuex";
import Routes from "@/utils/auth/routes";
import Storage from "@/utils/auth/storage";

/**
 * User defined routes displayed within App Header
 */
const {
  defaultRoutes,
  clerkRoutes,
  officerRoute,
  directorOneRoute,
  directorTwoRoute,
  retentionStaffRoute,
} = Routes;

export default {
  name: "header",
  data() {
    const vm = this;
    return {
      dialog: false,
      routes: {
        data_entry_clerk: clerkRoutes,
        officer_in_charge: officerRoute,
        director_1: directorOneRoute,
        director_2: directorTwoRoute,
        retention_staff: retentionStaffRoute,
      },
      menu: [
        {
          text: "DashBoard",
          event() {
            vm.$router.push("/dashboard");
          },
        },
        {
          text: "Logout",
          event() {
            vm.dialog = true;
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["user", "userGroup"]),
    // navbar header
    renderedRoutes() {
      return this.user
        ? this.routes[this.userGroup] || defaultRoutes
        : defaultRoutes;
    },
  },
  methods: {
    handleLogout() {
      this.dialog = false;
      Storage.clearToken();
      this.$store.commit("logout");
      this.$router.push({
        name: "OpenHISA",
      });
    },
    cancelLogout() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.header-image {
  max-height: 100%;
  min-height: 80%;
}
.toolbar {
  background: transparent linear-gradient(180deg, #175429 0%, #0e772e 100%);
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  background-repeat: no-repeat;
  background-origin: padding-box;
}
v-toolbar__title {
  top: 38px;
  left: 176px;
  width: 200px;
  height: 45px;
  text-align: left;
  font: Regular 36px/45px Trajan Pro;
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
}
a {
  color: white !important;
}

a:hover {
  text-decoration: none;
}
</style>
