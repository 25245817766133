<template>
  <v-app>
    <AppHeader />
    <router-view></router-view>
    <AppFooter />
  </v-app>
</template>

<script>
import AppHeader from "@/views/layouts/base/AppHeader.vue";
import AppFooter from "@/views/layouts/base/AppFooter.vue";

export default {
  name: "home",
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>
