<template>
  <v-footer class="font-weight-medium grey darken-4 py-0 bottom">
    <v-col cols="12" class="mx-auto pb-0">
      <div class="row text-light mt-5">
        <div class="col-sm-12 col-md-6">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 d-flex ml-auto">
              <img
                src="@/assets/images/png/OpenHISA Identity.png"
                alt="openhisa_logo"
                class="mr-1"
              />
              <span class="h5 text-light my-auto d-inline-block">OpenHISA</span>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mr-auto d-flex mr-auto">
              <img
                alt="Plateau State logo"
                class="mr-1"
                src="@/assets/images/png/Plateau state logo.png"
              />
              <p class="h5 text-light my-auto d-inline-block">
                <span class="d-block">Plateau State</span>
                <span class="mt-0">Government</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="row">
            <div class="col-md-6 mb-10">
              <h5 class="font-weight-bold">About</h5>
              <small class="d-block mt-2">Health Plans</small>
              <small class="d-block mt-2">Find a Facility</small>
              <small class="d-block mt-2">FAQ</small>
              <small class="d-block mt-2">Privacy Policy</small>
              <small class="d-block mt-2">Terms &amp; Conditions</small>
              <small class="d-block mt-2">Admin</small>
            </div>
            <div class="col-md-6 h-100">
              <h5 class="white--text font-weight-bold">Contact Us</h5>
              <small class="d-block mt-2">+2348023459876</small>
              <small class="d-block mt-2">info@plateaugovt.com</small>
              <small class="mt-2">
                <span>
                  &copy; OpenHISA
                  <strong>{{ year }}</strong>
                </span>
              </small>
            </div>
          </div>
        </div>
      </div>
    </v-col>
  </v-footer>
</template>
<script>
export default {
  name: "footer",
  computed: {
    year() {
      return new Date().getFullYear();
    }
  }
};
</script>
<style scoped>
.bottom {
  position: relative;
  bottom: 0px;
  left: 0px;
  width: 100%;
}
img {
  width: 25%;
  display: inline-block;
}
</style>
