/**
 * This mopdule provides wrappers around localStorage
 * to store and obtain user token (access, refresh)
 */

function storeUserTokens(tokens) {
  const { refresh, access } = tokens;
  // stores user access and refresh token
  localStorage.setItem("token", access);
  localStorage.setItem("refresh", refresh);
}

function getUserToken(name) {
  const token = localStorage.getItem(name);
  return token;
}

function setUserToken(name, value) {
  localStorage.setItem(name, value);
  return true;
}

function clearToken() {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh");
  localStorage.clear();
}

export default {
  getUserToken,
  setUserToken,
  storeUserTokens,
  clearToken
};
